import { getSportIcon } from 'markets-store/helpers/getSportIcon'
import type { IconName } from '@st/ui/components/StIcon/types'
import type { RenderFullTree } from '../../types'

interface UseSportListItemReturn {
  routeCategory: ComputedRef<string>
  routeTournament: ComputedRef<string>
  handleTournamentHeaderClick: (id: number, index: number) => void
  isVisibleEventItem: (id: number, index: number) => boolean
  sportIcon: ComputedRef<IconName>
}

export function useSportListItem(
  sportTree: Ref<RenderFullTree>,
): UseSportListItemReturn {
  const route = useRoute()
  const queryCategory = computed(() => {
    const querySportId = route.query.categoryId ?? []

    return !Array.isArray(querySportId) ? querySportId : querySportId[0]
  })
  const routeCategory = computed(
    () =>
      String(route?.params?.categoryId ?? '') ||
      String(queryCategory.value ?? ''),
  )
  const routeTournament = computed(() => route?.params?.tournamentId as string)

  const toogledTournaments = ref<Record<string, boolean>>({})
  function handleTournamentHeaderClick(id: number, index: number) {
    const idForCollapse = `${id}-${index}`
    if (toogledTournaments.value[idForCollapse]) {
      toogledTournaments.value[idForCollapse] =
        !toogledTournaments.value[idForCollapse]
    } else toogledTournaments.value[idForCollapse] = true
  }
  function isVisibleEventItem(id: number, index: number) {
    const idForCollapse = `${id}-${index}`
    return !toogledTournaments.value[idForCollapse]
  }

  const sportIcon = computed(
    () =>
      getSportIcon({
        sportId: sportTree.value.sport.id ?? 0,
        categoryId: sportTree.value.sport.categoryId,
      }) as IconName,
  )

  return {
    routeCategory,
    routeTournament,
    handleTournamentHeaderClick,
    isVisibleEventItem,
    sportIcon,
  }
}

import type { TSportEvent, CustomMenuItem } from 'markets-store/types'
import {
  buildEventsByEntityMap,
  getTournamentsListFromNode,
} from 'markets-store/helpers/filterCustomMenu'
import { SPORT_MENU_ITEMS } from 'markets-store/constants'
import { groupBy, sortBy } from '@st/utils'
import { useMarketsBookStore } from 'markets-store/marketsbook/store/marketsBookStore'
import type { RenderFullTree, TournamentNode } from '../../types'

export interface InitTreeOptions {
  state?: 'live' | 'prematch'
  sportId?: Ref<number | string> | number | string
  categoryId?: Ref<number> | number
  tournamentId?: Ref<number> | number
}
export function useInitSportTree(
  events: Ref<TSportEvent[]>,
  {
    state = 'prematch',
    sportId,
    categoryId,
    tournamentId,
  }: InitTreeOptions = {},
) {
  const marketsBookStore = useMarketsBookStore()
  const { marketsFiltersConfig } = storeToRefs(marketsBookStore)

  const customMenu = useCustomMenu()
  const eventsByEntity = computed(() =>
    buildEventsByEntityMap(customMenu.value, events.value),
  )

  function createTournament(
    eventByTournaments: Map<number, TSportEvent[]>,
    tournamentsByRoot: Map<number, CustomMenuItem[]>,
    { deepSort = true }: { deepSort?: boolean } = {},
  ) {
    const result: TournamentNode[] = []

    eventByTournaments.forEach((sportEvents, tourId) => {
      const tournament = tournamentsByRoot.get(tourId)?.[0]

      if (tournament && sportEvents.length) {
        result.push({
          category: customMenu.value.findById(
            SPORT_MENU_ITEMS.CATEGORY,
            tournament.categoryId ?? 0,
          ) as CustomMenuItem,
          sportEvents: sortBy(sportEvents, (item) => [item.scheduled]),
          tournament,
        })
      }
    })

    return deepSort
      ? sortBy(result, (tournament) => [
          tournament.category.sortIndex,
          tournament.category.name,
          tournament.tournament.sortIndex,
          tournament.tournament.name,
        ])
      : sortBy(result, (tournament) => [
          tournament.tournament.sortIndex,
          tournament.tournament.name,
        ])
  }
  function getTournamentsByEventStatus(
    sportEvents: TSportEvent[],
    rootMenu: CustomMenuItem,
    { deepSort = true }: { deepSort?: boolean } = {},
  ) {
    const liveEvents = sortBy(
      sportEvents.filter((item) => item.status),
      (live) => [live.scheduled],
    )
    const prematchEvents = sortBy(
      sportEvents.filter((item) => !item.status),
      (prematch) => [prematch.scheduled],
    )

    const liveByTournament = groupBy(liveEvents, (item) => item.tournamentId)
    const prematchByTournament = groupBy(
      prematchEvents,
      (item) => item.tournamentId,
    )

    const tournamentsByRoot = groupBy(
      getTournamentsListFromNode(rootMenu),
      (item) => item.id,
    )

    return [
      ...createTournament(liveByTournament, tournamentsByRoot, { deepSort }),
      ...createTournament(prematchByTournament, tournamentsByRoot, {
        deepSort,
      }),
    ]
  }

  const groupedEventsByDaysMap = computed(() => {
    const groupedByDays = groupBy(events.value, (item) =>
      item.status ? 0 : Math.floor(item.scheduled / 1000 / 60 / 60 / 24),
    )
    return Array.from(groupedByDays.keys())
      .sort()
      .map((key) => groupedByDays.get(key))
  })

  const fullSportsTree = computed(() =>
    customMenu.value.menu.reduce<RenderFullTree[]>((acc, root) => {
      const groupedTournaments: TournamentNode[] = []
      /* 
        для каждого отдельного дня
        создаем группы турниров для отрисовки
      */
      groupedEventsByDaysMap.value.forEach((eventsByDay) => {
        const resultTournaments = getTournamentsByEventStatus(
          eventsByDay,
          root,
          { deepSort: false },
        )

        if (resultTournaments.length) {
          groupedTournaments.push(...resultTournaments)
        }
      })

      const sportIdForConfig =
        (root.type === 'category' ? root.sportId : root.id) ?? 'default'
      const marketsConfig =
        marketsFiltersConfig.value?.[sportIdForConfig]?.[state] ??
        marketsFiltersConfig.value?.default?.[state]
      if (groupedTournaments.length) {
        acc.push({
          sport: root,
          marketsConfig,
          tournaments: groupedTournaments,
        })
      }

      return acc
    }, []),
  )
  const singleSportTree = computed(() => {
    const rootMenu = customMenu.value.menu.find(
      (item) => item.id === unref(sportId),
    )
    const customRootMenu = customMenu.value.menu.find((item) => {
      /* если есть sportId у кроневого элемента меню, то это кастом  */
      let isCustomRootElement = item.sportId === unref(sportId)
      if (!isCustomRootElement) return false

      /* если кастом является категорией */
      if (unref(categoryId) && !item.categoryId) {
        isCustomRootElement = item.id === unref(categoryId)
        return isCustomRootElement
      }

      /* если кастом является турниром */
      if (unref(tournamentId)) {
        isCustomRootElement = item.id === unref(tournamentId)
        return isCustomRootElement
      }

      return false
    })

    const menuRootForTournaments = customRootMenu || rootMenu
    if (!menuRootForTournaments) return []

    const tournaments = getTournamentsByEventStatus(
      events.value,
      menuRootForTournaments,
    )

    const sportIdForConfig = unref(sportId) ?? 'default'
    const marketsConfig =
      marketsFiltersConfig.value?.[sportIdForConfig]?.[state] ??
      marketsFiltersConfig.value?.default?.[state]
    return [
      {
        sport: menuRootForTournaments,
        marketsConfig,
        tournaments,
      },
    ]
  })

  return {
    fullSportsTree,
    singleSportTree,
    eventsByEntity,
  }
}

import type { RenderFullTree, TournamentNode } from '../../types'

const MAX_PER_PAGE = 15

type MaybeRef<T> = T | Ref<T>

export function useSportList({
  renderFullTree,
  perPage = 2,
  disabled = false,
  routeCategory = '',
  routeTournament = '',
}: {
  renderFullTree: Ref<RenderFullTree>
  perPage: number
  disabled: MaybeRef<boolean>
  routeCategory?: MaybeRef<string>
  routeTournament?: MaybeRef<string>
}) {
  const itemsOnPage = ref(perPage)
  const total = computed(() =>
    renderFullTree.value.tournaments.reduce(
      (acc, item) => acc + item.sportEvents.length,
      0,
    ),
  )
  const canLoadMore = computed(
    () => !unref(disabled) && itemsOnPage.value <= total.value,
  )
  async function loadMore() {
    if (!canLoadMore.value) return
    const perPagePreloadCount =
      itemsOnPage.value > MAX_PER_PAGE ? MAX_PER_PAGE : perPage

    itemsOnPage.value += perPagePreloadCount
  }

  const renderPartTree = computed(() => {
    let eventsCount = 0
    const treeWithLimit: TournamentNode[] = []

    const categoryId = unref(routeCategory)

    /* 
      так как кастомы турниры могут быть в урле категориями
      нужно проверить categoryId из урла на совпадение с
      tournament.id
    */
    let tournaments = categoryId
      ? renderFullTree.value.tournaments.filter(
          (item) =>
            item.category.id === Number(categoryId) ||
            item.tournament.id === Number(categoryId),
        )
      : renderFullTree.value.tournaments

    const tournamentId = unref(routeTournament)
    tournaments = tournamentId
      ? renderFullTree.value.tournaments.filter(
          (item) => item.tournament.id === Number(tournamentId),
        )
      : tournaments

    // eslint-disable-next-line no-restricted-syntax
    for (const tournamentNode of tournaments) {
      if (eventsCount >= itemsOnPage.value) return treeWithLimit

      const { sportEvents } = tournamentNode
      const copiedTournamentNode: TournamentNode = {
        tournament: tournamentNode.tournament,
        category: tournamentNode.category,
        sportEvents: [],
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const sportEvent of sportEvents) {
        if (eventsCount >= itemsOnPage.value) return treeWithLimit
        copiedTournamentNode.sportEvents.push(sportEvent)
        eventsCount += 1
      }

      if (tournamentId && treeWithLimit.length) {
        /* на странице турнира выводим в одной группе все его события */
        treeWithLimit[0].sportEvents.push(...copiedTournamentNode.sportEvents)
      } else treeWithLimit.push(copiedTournamentNode)
    }

    return treeWithLimit
  })

  return {
    renderPartTree,
    total,
    canLoadMore,
    loadMore,
    itemsOnPage,
  }
}
